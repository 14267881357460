import * as React from "react";
import {DependencyList, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {findDescendantFileInfo, normPath, Resource} from "./util";
import {FileList} from "./api-model";
import {API} from "./api";
import {DriveContext} from "./context";

export function useCurrentPath(): string {
    const location = useLocation();
    return normaliseCurrentPath(location.pathname);
}

export function getCurrentPath(): string {
    return normaliseCurrentPath(window.location.hash.substring(1));
}

function normaliseCurrentPath(currentPath: string): string {
    return normPath(currentPath.split("/").map(p => decodeURIComponent(p)).join("/"));
}

/**
 * Adds given event listener to document.
 * @param eventType Type of event to listen to.
 * @param handler Event handler function.
 * @param options Options for document.addEventListener()
 */
export function useEventHandler<T extends Event>(eventType: string, handler: (e: T) => void, options?: boolean | AddEventListenerOptions) {
    useEffect(() => {
        document.addEventListener(eventType, handler as EventListener, options);
        return () => document.removeEventListener(eventType, handler as EventListener, options);
    }, [eventType, handler, options]);
}

/**
 * Calls given function when user clicks outside given referenced DOM node.
 * @param ref Node outside of which clicks will be detected.
 * @param onOutsideClick Function to be called when user clicks outside given ref.
 */
export function useOutsideClickDetector(ref: React.RefObject<HTMLElement>, onOutsideClick: (e: MouseEvent) => void) {
    const handleOutsideClick = useCallback((e: MouseEvent) => {
        if (ref.current && !ref.current.contains(e.target as Node)) {
            onOutsideClick(e);
        }
    }, [onOutsideClick]);

    useEventHandler("mousedown", handleOutsideClick, true);
}

/**
 * Focuses given element on render.
 */
export function useAutoFocus(ref: React.RefObject<HTMLElement>, deps?: DependencyList) {
    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, deps);
}

export function useDirResource(path: string): Resource<FileList> {
    const context = useContext(DriveContext);
    const [dirResource, setDirResource] = useState<Resource<FileList>>({state: "EMPTY"});

    useEffect(() => {
        const promise = API.readDirectory(path);
        setDirResource({state: "LOADING", promise, data: dirResource.data});
        promise.then(data => setDirResource({state: "OK", data, promise}))
            .catch(error => setDirResource({state: "ERROR", promise, error}));
    }, [path, findDescendantFileInfo(context.root, path)]);

    return dirResource;
}

export function useFileData(path: string): Resource<Blob> {
    const [fileData, setFileData] = useState<Resource<Blob>>({state: "EMPTY"});

    useEffect(() => {
        const promise = API.read(path);
        setFileData({state: "LOADING", promise});
        promise.then(data => setFileData({state: "OK", data, promise}))
            .catch(error => setFileData({state: "ERROR", promise, error}));
    }, [path]);

    return fileData;
}

export function useDoubleClick(onDoubleClick: (e: React.MouseEvent) => void) {
    const lastClickRef = useRef(0);
    return (e: React.MouseEvent) => {
        if (e.ctrlKey || e.shiftKey || e.altKey) return;
        const now = Date.now();
        if (now - lastClickRef.current < 400) {
            onDoubleClick(e);
            lastClickRef.current = 0;
        } else {
            lastClickRef.current = now;
        }
    };
}

export function joinRefs<T>(...refs: (React.MutableRefObject<T> | React.RefCallback<T>)[]): React.RefCallback<T> {
    return (value: T) => {
        for (let ref of refs) {
            if (typeof ref === "function") {
                ref(value);
            } else {
                ref.current = value;
            }
        }
    };
}