import React from "react";

export interface KeyCombination {
    key: string;
    ctrl?: boolean;
    shift?: boolean;
    alt?: boolean;
}

export function keyBindCode(combination: KeyCombination) {
    return `${combination.key.toUpperCase()}-${0b000 | (combination.ctrl?1:0) | (combination.shift?2:0) | (combination.alt?4:0)}`;
}

export type KeyBindActionMap = {[keyBindCode: string]: undefined | ((e: React.KeyboardEvent) => void)};

/**
 * Wraps given function to mark any received events as consumed.
 */
export function toEventConsumer(fn: ((evt: React.KeyboardEvent) => void) | (() => void)) {
    return (event: React.KeyboardEvent): void => {
        fn(event);
        event.stopPropagation();
        event.preventDefault();
    };
}

export function keyboardEventHandler(keyBinds: KeyBindActionMap) {
    return (e: React.KeyboardEvent) => {
        const bindCode = keyBindCode({key: e.key, ctrl: e.ctrlKey, shift: e.shiftKey, alt: e.altKey});
        const boundCallback = keyBinds[bindCode];
        if (boundCallback) {
            boundCallback(e);
        }
    };
}