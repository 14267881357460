import "./SpinnerOverlay.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {Overlay} from "./Overlay";
import React, {HTMLAttributes} from "react";
import classNames from "classnames";

interface SpinnerOverlayProps extends HTMLAttributes<HTMLDivElement> {}

export function SpinnerOverlay(props: SpinnerOverlayProps) {
    return <Overlay {...props} className={classNames("SpinnerOverlay", props.className)} centerContent>
        <FontAwesomeIcon icon={faSpinner} spin/>
    </Overlay>;
}