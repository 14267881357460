import "./Breadcrumbs.scss";
import React, {useContext} from "react";
import {getAncestorPaths, getNameFromPath} from "../util";
import {Link} from "react-router-dom";
import {uniq} from "lodash";
import {useCurrentPath} from "../hooks";
import {DriveContext} from "../context";

export function Breadcrumbs() {
    const {root} = useContext(DriveContext);
    const currentPath = useCurrentPath();
    const ancestorPaths = uniq([...getAncestorPaths(currentPath), currentPath].filter(p => p !== "/"));
    const parts = ancestorPaths.map(p => ({name: getNameFromPath(p), path: p}));

    return <div className="Breadcrumbs">
        <Link className="path-part" to={"/"}>{root.name || "Root"}</Link>
        {parts.map(p => <React.Fragment key={p.path}>
            <span className={"separator"}>/</span>
            <Link className="path-part" to={p.path}>{p.name}</Link>
        </React.Fragment>)}
    </div>;
}
