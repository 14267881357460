import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {FileInfoList, FileList} from "./api-model";
import {FileWithPath} from "react-dropzone";

// set to http://localhost:3030 for development
const baseUrl = "";

const Axios = axios.create({
    baseURL: baseUrl,
    validateStatus: () => true
});

export namespace API {

    export async function loadFileSystem(): Promise<FileInfoList> {
        const response = await Axios.get<FileInfoList | string>("/fs");
        if (isSuccess(response)) {
            return response.data as FileInfoList;
        } else {
            console.error("bad response from server while loading file system: ", response.data);
            throw response.data as string;
        }
    }

    export async function readDirectory(path: string): Promise<FileList> {
        const response = await Axios.get<FileList | string>("/fs/" + encodeURIComponent(path) + "/dir");
        if (isSuccess(response)) {
            return response.data as FileList;
        } else {
            console.error("bad response from server while loading directory content of '" + path + "': ", response.data);
            throw response.data as string;
        }
    }

    export async function mkdir(path: string): Promise<void> {
        const response = await Axios.post<string>("/fs/" + encodeURIComponent(path) + "/mkdir");
        if (!isSuccess(response)) throw response.data;
    }

    export async function read(path: string): Promise<Blob> {
        const response = await Axios.get<Blob>("/fs/" + encodeURIComponent(path), {responseType: "blob"});
        if (!isSuccess(response)) throw await response.data.text();
        return response.data;
    }

    export async function write(path: string, body: string, knownSha256?: string): Promise<void> {
        const config: AxiosRequestConfig = {headers: {"Content-Type": "application/octet-stream"}};
        if (knownSha256) {
            config.headers!["X-KDrive-Check-SHA256"] = knownSha256;
        }

        const response = await Axios.put<string>("/fs/" + encodeURIComponent(path), body, config);
        if (!isSuccess(response)) throw response.data;
    }

    export async function upload(path: string, file: FileWithPath, onProgress: (pct: number) => void): Promise<void> {
        const onUploadProgress = (e: ProgressEvent) => onProgress(e.loaded / e.total);

        const config: AxiosRequestConfig = {
            headers: {"Content-Type": "application/octet-stream"},
            responseType: "text",
            onUploadProgress
        };

        const response = await Axios.put<string>("/fs/" + encodeURIComponent(path), file, config);
        if (!isSuccess(response)) throw response.data;
    }

    export async function getShareCode(path: string) {
        const response = await Axios.post<string>("/fs/" + encodeURIComponent(path) + "/share");
        if (!isSuccess(response)) throw response.data;
        return response.data;
    }

    export function triggerDownload(downloadCode: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = baseUrl + "/download/" + downloadCode;
        a.click();
        document.body.removeChild(a);
    }

    export async function move(src: string, dst: string): Promise<void> {
        const response = await Axios.post<string>("/fs/" + encodeURIComponent(src) + "/move/" + encodeURIComponent(dst));
        if (!isSuccess(response)) throw response.data;
    }

    export async function copy(src: string, dst: string): Promise<void> {
        const response = await Axios.post<string>("/fs/" + encodeURIComponent(src) + "/copy/" + encodeURIComponent(dst));
        if (!isSuccess(response)) throw response.data;
    }

    export async function remove(path: string): Promise<void> {
        const response = await Axios.delete<string>("/fs/" + encodeURIComponent(path));
        if (!isSuccess(response)) throw response.data;
    }

}

function isSuccess(response: AxiosResponse): boolean {
    return response.status >= 200 && response.status < 300;
}
