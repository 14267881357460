import "./EditableText.scss";
import React, {HTMLAttributes, useEffect, useRef} from "react";
import classNames from "classnames";

export interface EditableTextProps extends HTMLAttributes<HTMLDivElement> {
    children: string;
    editing?: boolean;
    onChanged?: (e: string) => void;
    onCancel?: () => void;
    selectOnMount?: boolean;
}

export function EditableText({children, editing = true, onChanged, onCancel, selectOnMount = true, ...divProps}: EditableTextProps) {
    const ref = useRef<HTMLInputElement>(null);

    // focus editable input when this entry becomes editable
    useEffect(() => {
        if (selectOnMount && editing) {
            setTimeout(() => {
                if (ref.current) {
                    if (window.getSelection()) {
                        const range = document.createRange();
                        range.selectNodeContents(ref.current);
                        window.getSelection()!.removeAllRanges();
                        window.getSelection()!.addRange(range);
                    }
                    ref.current.scrollIntoView({block: "center", inline: "center", behavior: "smooth"});
                    ref.current.focus();
                }
            }, 0);
        }
    }, [selectOnMount, editing]);

    useEffect(() => {
        if (ref.current) ref.current.textContent = children;
    }, [children]);

    useEffect(() => {
        if (!ref.current) return;
        const element = ref.current;
        const cancelEdit = () => {
            if (element) {
                element.textContent = children;
                onCancel && onCancel();
            }
        };
        const onKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                e.stopPropagation();
                e.preventDefault();

                const newValue = element.textContent || "";
                onChanged?.(newValue);
            } else if (e.key === "Escape") {
                e.stopPropagation();
                e.preventDefault();
                cancelEdit();
            }
        };
        element.addEventListener("keydown", onKeyDown);
        element.addEventListener("blur", cancelEdit);
        return () => {
            element.removeEventListener("keydown", onKeyDown);
            element.removeEventListener("blur", cancelEdit);
        };
    }, [children]);

    return <div {...divProps}
                className={classNames("EditableText", divProps.className)}
                ref={ref}
                spellCheck={false}
                contentEditable={editing}/>;
}
