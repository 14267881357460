import {toast} from "react-toastify";

export function toastErrorMessage(title: string, body: string) {
    toast(<>
        <div style={{marginBottom: 5}}>
            <strong>{title}</strong>
        </div>
        <div>{body}</div>
    </>, {type: "error"});
}
