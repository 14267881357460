import "./MainPage.scss";
import React from "react";
import {clamp, findDescendantFileInfo, RouterProps, setStateAsync} from "../util";
import {throttle} from "lodash";
import {FileBrowser} from "../components/FileBrowser";
import {DriveContext, DriveContextType, FileBrowserClipboard, withRouterProps} from "../context";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileViewer from "../components/FileViewer";
import {Breadcrumbs} from "../components/Breadcrumbs";
import {DraggableCore} from "react-draggable";
import {DirectoryViewer} from "../components/DirectoryViewer";
import {toastErrorMessage} from "../toast-util";
import {FileInfo} from "../api-model";
import {FileSystemController} from "../file-system-controller";

const FILE_BROWSER_WIDTH_STORAGE_KEY = "MainPage.fileBrowserWidth";

interface MainPageState {
    loading: boolean;
    root: FileInfo;
    clipboard?: FileBrowserClipboard;

    fileBrowserWidth: number;
}

class MainPage extends React.Component<RouterProps, MainPageState> {

    private readonly controller: FileSystemController;

    constructor(props: RouterProps) {
        super(props);

        const storedWidth = localStorage.getItem(FILE_BROWSER_WIDTH_STORAGE_KEY);
        const fileBrowserWidth = !!storedWidth && !isNaN(parseFloat(storedWidth)) ? parseFloat(storedWidth) : 350;
        this.state = {
            fileBrowserWidth,
            root: {name: "", children: []},
            loading: true
        };
        this.controller = new FileSystemController(
            loading => this.setState({loading}),
            root => setStateAsync(this, {root}),
            props.navigate
        );
    }

    async componentDidMount() {
        try {
            await this.controller.loadFileSystem();
        } catch (e) {
            toastErrorMessage("Unable to load file system", (e as string).toString());
            throw e;
        }
    }

    private onFileBrowserWidthResized = throttle((width: number) => {
        if (isNaN(width)) return;
        width = clamp(width, 150, 700);
        this.setState({fileBrowserWidth: width}, () => {
            localStorage.setItem(FILE_BROWSER_WIDTH_STORAGE_KEY, String(width));
        });
    }, 1000 / 60);

    render() {
        const {fileBrowserWidth} = this.state;

        const context: DriveContextType = {
            loading: this.state.loading,
            root: this.state.root,
            controller: this.controller,
            clipboard: this.state.clipboard,
            setClipboard: clipboard => this.setState({clipboard: clipboard || undefined})
        };

        return <DriveContext.Provider value={context}>
            <div className="MainPage" style={{gridTemplateColumns: `${fileBrowserWidth}px 1px 1fr`}}>
                <Breadcrumbs/>
                <FileBrowser/>
                <div className="file-browser-resizer">
                    <DraggableCore handle=".file-browser-resize-handle"
                                   enableUserSelectHack={true}
                                   onDrag={e => this.onFileBrowserWidthResized((e as MouseEvent).clientX)}
                                   onStop={e => this.onFileBrowserWidthResized((e as MouseEvent).clientX)}>
                        <div className="file-browser-resize-handle"/>
                    </DraggableCore>
                </div>
                {!!findDescendantFileInfo(context.root, this.props.currentPath)?.children ?
                    <DirectoryViewer key={this.props.currentPath}/>
                    :
                    <FileViewer key={this.props.currentPath}/>
                }
                <ToastContainer theme={"dark"} position={"bottom-right"} hideProgressBar/>
            </div>
        </DriveContext.Provider>;
    }

}

export default withRouterProps(MainPage);