import "./DirectoryViewer.scss";
import React, {useContext, useMemo, useRef} from "react";
import {joinRefs, useCurrentPath, useDirResource, useDoubleClick} from "../hooks";
import {SpinnerOverlay} from "./SpinnerOverlay";
import {FileData} from "../api-model";
import {DriveContext} from "../context";
import {DateTime} from "luxon";
import {concatPaths, FileEntriesDropItem, getIconForFileData, getParentPath, getUrlToPath, isMobile, noop, prettyByteLabel} from "../util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ErrorOverlay} from "./ErrorOverlay";
import classNames from "classnames";
import {keyboardEventHandler} from "../keybind-util";
import {FileWithPath} from "react-dropzone";
import {ProgressDialog} from "./ProgressDialog";
import {
    FileEntryDropArea, getFileContextMenu,
    getFileEntryKeyBinds,
    onFileEntriesDropped,
    useFileDropZone,
    useFileEntryDragAndDrop,
    useFileRename,
    useFileSelection,
    useFileUpload
} from "../file-browser-commons";
import {EditableText} from "./EditableText";
import {useColumnResize} from "../column-resizer";
import {SortBy, useSorted} from "../sorted";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {useNavigate} from "react-router-dom";
import {Overlay} from "./Overlay";
import {contextMenuHandler} from "../render-util";

export function DirectoryViewer() {
    const context = useContext(DriveContext);
    const currentPath = useCurrentPath();
    const dirResource = useDirResource(currentPath);

    const isLoading = context.loading || ["EMPTY", "LOADING"].includes(dirResource.state);
    const isError = dirResource.state === "ERROR";

    return <div className="DirectoryViewer">
        <DirectoryView files={dirResource.data?.files ?? []}/>
        {isLoading && <SpinnerOverlay/>}
        {isError && <ErrorOverlay>Unable to load directory: {dirResource.error}</ErrorOverlay>}
    </div>;
}

function DirectoryView({files: unsortedFiles}: {files: FileData[]}) {
    const currentPath = useCurrentPath();
    const navigate = useNavigate();
    const context = useContext(DriveContext);

    const {sorted: files, sortedBy, toggleSort} = useSorted(unsortedFiles, {localStorageKey: "DirectoryViewer.sort-by"});

    const allPaths = useMemo(() => files.map(f => concatPaths(currentPath, f.name)), [files]);
    const {selectedPaths, onFileClick, resetSelection} = useFileSelection(allPaths);
    const {startUpload, uploadProgress} = useFileUpload();
    const {renamingPath, setRenamingPath, onRename} = useFileRename();
    const onKeyDown = keyboardEventHandler(getFileEntryKeyBinds(context, selectedPaths, resetSelection, setRenamingPath));

    const tableRef = useRef<HTMLTableElement>(null);
    const columnIds = useMemo(() => ["name", "created", "modified", "size"], []);
    const {columnWidths, renderResizeHandle} = useColumnResize(tableRef, columnIds, {tableId: "DirectoryViewer.column-widths." + currentPath, relative: true});

    const onGoToParentRowClicked = useDoubleClick(() => navigate(getUrlToPath(getParentPath(currentPath))));

    const {getRootProps, getInputProps, isDragActive} = useFileDropZone(files => startUpload(currentPath, files));

    return <>
        <table ref={tableRef} cellSpacing={0} onKeyDown={onKeyDown}>
            <thead>
            <tr>
                <th title="Name"
                    style={{width: columnWidths["name"]}}
                    onClick={() => toggleSort("name")}>
                    Name
                    <SortIndicator sortKey={"name"} sortedBy={sortedBy}/>
                    {renderResizeHandle("name")}
                </th>
                <th title="Date created"
                    style={{width: columnWidths["created"]}}
                    onClick={() => toggleSort("created_ts")}>
                    Date created
                    <SortIndicator sortKey={"created_ts"} sortedBy={sortedBy}/>
                    {renderResizeHandle("created")}
                </th>
                <th title="Date modified"
                    style={{width: columnWidths["modified"]}}
                    onClick={() => toggleSort("modified_ts")}>
                    Date modified
                    <SortIndicator sortKey={"modified_ts"} sortedBy={sortedBy}/>
                    {renderResizeHandle("modified")}
                </th>
                <th title="Size"
                    style={{width: columnWidths["size"]}}
                    onClick={() => toggleSort("size")}>
                    Size
                    <SortIndicator sortKey={"size"} sortedBy={sortedBy}/>
                </th>
            </tr>
            </thead>
            <tbody>
            {currentPath !== "/" && <tr className="file-row">
                <td title={"Go to " + getParentPath(currentPath)} onClick={onGoToParentRowClicked}>
                    <div className="name-column">
                        <span className="icon"/>
                        <span className="label">..</span>
                    </div>
                </td>
                <td/><td/><td/>
            </tr>}
            {files.map(f => {
                const filePath = concatPaths(currentPath, f.name);
                const isSelected = selectedPaths.includes(filePath);
                return <FileEntry key={f.name}
                                  file={f}
                                  isSelected={isSelected}
                                  dragItem={{
                                      paths: isSelected ? allPaths : [filePath],
                                      draggedPath: filePath
                                  }}
                                  onClick={e => onFileClick(filePath, e.ctrlKey, e.shiftKey)}
                                  onDoubleClick={() => navigate(getUrlToPath(filePath))}
                                  onContextMenu={contextMenuHandler(() => getFileContextMenu(context, filePath, f.ftype === "d", selectedPaths, setRenamingPath, setRenamingPath))}
                                  onFileEntriesDropped={files => onFileEntriesDropped(context, files, f.ftype === "d" ? filePath : currentPath)}
                                  onFilesDropped={files => startUpload(f.ftype === "d" ? filePath : currentPath, files)}
                                  renaming={renamingPath === filePath}
                                  onRename={newName => onRename(filePath, newName)}
                                  onRenameCancel={() => setRenamingPath(undefined)}/>
            })}
            </tbody>
        </table>
        <div {...getRootProps()}
             className="file-drop-zone"
             onClick={e => isMobile() ? getRootProps().onClick?.(e) : noop()}
             onContextMenu={contextMenuHandler(() => getFileContextMenu(context, currentPath, true, selectedPaths, setRenamingPath, setRenamingPath))}>
            <input {...getInputProps()}/>
            {isDragActive && <Overlay nonBlocking>
                <div className="drop-label">Drop files to upload</div>
            </Overlay>}
        </div>
        {uploadProgress && <ProgressDialog {...uploadProgress}/>}
    </>;
}

interface FileEntryProps {
    file: FileData;
    isSelected: boolean;
    dragItem: FileEntriesDropItem;
    onFileEntriesDropped: (paths: string[]) => void;
    onClick: (e: React.MouseEvent) => void;
    onDoubleClick: (e: React.MouseEvent) => void;
    onContextMenu: (e: React.MouseEvent) => void;
    onFilesDropped: (files: FileWithPath[]) => void;
    renaming: boolean;
    onRename: (newName: string) => void;
    onRenameCancel: () => void;
}

function FileEntry(props: FileEntryProps) {
    const currentPath = useCurrentPath();
    const {file, isSelected, dragItem, onFileEntriesDropped, onDoubleClick, onContextMenu, onFilesDropped, renaming, onRename, onRenameCancel} = props;
    const path = concatPaths(currentPath, file.name);

    const {dragRef, dropRef, dragProps} = useFileEntryDragAndDrop({path, isDir: file.ftype === "d"}, dragItem, onFileEntriesDropped);
    const {getRootProps, getInputProps, isDragActive} = useFileDropZone(onFilesDropped);
    const dragHandleRef = joinRefs(dragRef, dropRef);

    const onRowClicked = useDoubleClick(onDoubleClick);
    const onClick = (e: React.MouseEvent) => {
        props.onClick(e);
        if (!e.isPropagationStopped()) onRowClicked(e);
    };

    return <tr {...getRootProps()}
               key={file.name}
               className={classNames("file-row", {"selected": isSelected})}
               onClick={e => onClick(e)}
               onContextMenu={onContextMenu}>
        <td title={file.name}>
            <input {...getInputProps()} className="drop-input" disabled={true}/>
            <FileEntryDropArea ref={dragHandleRef}
                               {...dragProps}
                               className={"name-column"}
                               path={path}
                               isDroppingFiles={isDragActive}>
                <span className={classNames("icon", {"folder": file.ftype === "d"})}>
                    <FontAwesomeIcon icon={getIconForFileData(file)}/>
                </span>
                <EditableText className="label"
                              editing={renaming}
                              title={file.name}
                              onChanged={onRename}
                              onCancel={onRenameCancel}>
                    {file.name}
                </EditableText>
            </FileEntryDropArea>
        </td>
        <td title={DateTime.fromMillis(file.created_ts).toLocaleString(DateTime.DATETIME_FULL)}>{formatTS(file.created_ts)}</td>
        <td title={DateTime.fromMillis(file.modified_ts).toLocaleString(DateTime.DATETIME_FULL)}>{formatTS(file.modified_ts)}</td>
        <td title={file.ftype === "f" ? prettyByteLabel(file.size) : undefined}>
            {file.ftype === "f" ? prettyByteLabel(file.size) : undefined}
        </td>
    </tr>;
}

function SortIndicator({sortKey, sortedBy}: {sortKey: string, sortedBy?: SortBy<any>}) {
    if (!sortedBy || sortedBy.key !== sortKey) return null;

    return <FontAwesomeIcon className="SortIndicator" icon={sortedBy.ascending ? faChevronUp : faChevronDown}/>;
}

function formatTS(ts: number): string {
    const dt = DateTime.fromMillis(ts);
    const now = DateTime.now();
    const isSameYear = now.year === dt.year;
    const isSameMonth = isSameYear && now.month === dt.month;
    const isSameDay = isSameMonth && now.day === dt.day;
    if (isSameDay) { // is today
        return dt.toFormat("'Today,' HH:mm");
    } else if (isSameYear) {
        return dt.toFormat("d LLLL, HH:mm");
    } else {
        return dt.toFormat("dd/MM/yyyy HH:mm");
    }
}
