import "./FileViewer.scss";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DriveContext} from "../context";
import {getFileExt, getNameFromPath} from "../util";
import {useAutoFocus, useCurrentPath, useFileData} from "../hooks";
import {SpinnerOverlay} from "./SpinnerOverlay";
import {ErrorOverlay} from "./ErrorOverlay";
import {Overlay} from "./Overlay";
import {keyBindCode, keyboardEventHandler, toEventConsumer} from "../keybind-util";
import {sha256} from "js-sha256";
import {toastErrorMessage} from "../toast-util";
import {API} from "../api";

export default function FileViewer() {
    const context = useContext(DriveContext);
    const currentPath = useCurrentPath();
    const fileDataResource = useFileData(currentPath);

    const isLoading = context.loading || ["EMPTY", "LOADING"].includes(fileDataResource.state);
    const isError = fileDataResource.state === "ERROR";

    return <div className="FileViewer">
        {isLoading && <SpinnerOverlay/>}
        {isError && <ErrorOverlay>Unable to load file: {fileDataResource.error}</ErrorOverlay>}
        {!isLoading && fileDataResource.state === "OK" && <FileView filename={getNameFromPath(currentPath)} data={fileDataResource.data}/>}
    </div>;
}

interface FileViewProps {
    filename: string;
    data: Blob;
}

function FileView(props: FileViewProps) {
    const ext = getFileExt(props.filename).toLowerCase();
    const isImage = ["png", "jpeg", "jpg", "gif", "tif", "tiff", "bmp", "eps", "svg"].includes(ext);

    return <>
        {isImage && <ImageView {...props}/>}
        {!isImage && <TextView {...props}/>}
    </>;
}

function ImageView(props: FileViewProps) {
    const [src] = useState(() => URL.createObjectURL(props.data));
    return <Overlay className="ImageView">
        <img src={src}/>
    </Overlay>;
}

// TODO set unsaved changes warning for when user tries to navigate away or close tab
function TextView(props: FileViewProps) {
    const currentPath = useCurrentPath();

    const [hideBinary, setHideBinary] = useState(false);
    const [text, setText] = useState<string | undefined>();
    const [savedMD5, setSavedSha256] = useState("");
    const [saved, setSaved] = useState(true);
    const [saving, setSaving] = useState(false);
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        props.data.text().then(t => {
            // count encoding errors
            let errCount = 0;
            for (let i = 0; i < t.length; i++) {
                if (t.charAt(i) === "�") errCount++;
            }

            // hide contents if there are too many encoding errors
            if (errCount / t.length > 0.1) {
                setHideBinary(true);
            }

            setSavedSha256(getSha256(t));
            setText(t);
        });
    }, [props.data]);

    const ref = useRef<HTMLTextAreaElement>(null);
    useAutoFocus(ref, [hideBinary, text != null]);

    const onChange = (newText: string) => {
        setText(newText);
        setSaved(false);
        if (!touched) setTouched(true);
    };

    const onSave = async () => {
        if (text == null) return;

        setSaving(true);
        try {
            await API.write(currentPath, text, savedMD5);
            setSavedSha256(getSha256(text));
            setSaved(true);
        } catch (e) {
            // TODO special handling for HTTP 412
            console.error("error while saving text: ", e);
            toastErrorMessage("Unable to save", e as string);
        } finally {
            setSaving(false);
        }
    };

    const onKeyDown = keyboardEventHandler({[keyBindCode({key: "s", ctrl: true})]: toEventConsumer(onSave)});

    return <div className="TextView" onKeyDown={onKeyDown}>
        {!hideBinary && text != null && <>
            {saving && <SpinnerOverlay/>}
            <textarea ref={ref} value={text} onChange={e => onChange(e.target.value)} spellCheck={false}/>
            {touched && <div className="save-panel" onClick={onSave}>
                {saved ? "Saved" : (saving ? "Saving..." : "Save")}
            </div>}
        </>}
        {hideBinary && <Overlay>
            <div className="binary-overlay">
                <div className="warning">This file appears to be binary.</div>
                <div className="button" onClick={() => setHideBinary(false)}>Click here to view anyway</div>
            </div>
        </Overlay>}
    </div>;
}

function getSha256(text: string): string {
    return sha256(text);
}
