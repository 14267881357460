import "./ContextMenu.scss";
import React, {useLayoutEffect, useRef, useState} from "react";
import {useEventHandler, useOutsideClickDetector} from "../hooks";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";

const KC_ARROW_LEFT = 37;
const KC_ARROW_UP = 38;
const KC_ARROW_RIGHT = 39;
const KC_ARROW_DOWN = 40;

const KC_ENTER = 13;
const KC_ESCAPE = 27;

export interface ContextMenuEntryData {
    text: string;
    icon?: IconDefinition;
    shortcut?: string;
    subMenu?: ContextMenuData;
    enabled?: boolean;
    selectable?: boolean;
    callback?: () => void;
}

export type ContextMenuData = ContextMenuEntryData[][];

export interface ContextMenuProps {
    x: number;
    y: number;
    onClose: () => void;
    content: ContextMenuData
}

export const ContextMenu: React.FC<ContextMenuProps> = ({content, x, y, onClose}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [highlight, setHighlight] = useState<number[]>([]);

    useOutsideClickDetector(ref, onClose);
    useEventHandler("wheel", onClose, {capture: true, once: true});

    useLayoutEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    });

    const getActiveHighlight = (content: ContextMenuData, highlight: number[], depth: number = 0): {menu: ContextMenuData, highlighted: number, depth: number} => {
        if (highlight.length <= 1 || highlight.length === 2 && highlight[1] === -1) return {menu: content, highlighted: highlight[0], depth};
        else return getActiveHighlight(content.flatMap(a => a)[highlight[0]].subMenu!, highlight.slice(1), depth + 1);
    };

    const isContentEmpty = (content: ContextMenuData) => !content.some(c => c.length);

    const handleKeyPress = (key: number) => {
        const {menu: activeMenu, highlighted: activeHighlight, depth} = getActiveHighlight(content, highlight);
        const activeMenuEntries = activeMenu.flatMap(e => e).map((e, i) => ({entry: e, index: i}))
            .filter(e => (e.entry.selectable == null || e.entry.selectable) && (e.entry.enabled == null || e.entry.enabled));

        const firstHighlightable = activeMenuEntries[0].index;
        const lastHighlightable = activeMenuEntries[activeMenuEntries.length - 1].index;

        const previousHighlightable = activeHighlight <= 0 ? lastHighlightable :
            (activeMenuEntries.slice().reverse().find(e => e.index < activeHighlight) || {index: lastHighlightable}).index;
        const nextHighlightable = activeHighlight < 0 ? firstHighlightable :
            (activeMenuEntries.find(e => e.index > activeHighlight) || {index: firstHighlightable}).index;

        switch (key) {
            case KC_ARROW_UP:
                setHighlight([...highlight.slice(0, depth), previousHighlightable]);
                break;
            case KC_ARROW_DOWN:
                setHighlight([...highlight.slice(0, depth), nextHighlightable]);
                break;
            case KC_ARROW_LEFT:
                if (highlight[highlight.length - 1] !== -1) {
                    setHighlight([...highlight.slice(0, highlight.length - 1), -1]);
                } else if (highlight.length > 1) {
                    setHighlight([...highlight.slice(0, highlight.length - 1)]);
                }
                break;
            case KC_ARROW_RIGHT:
                if (highlight[highlight.length - 1] === -1) {
                    setHighlight([...highlight.slice(0, highlight.length - 1), 0]);
                }
                break;
            case KC_ENTER:
                if (activeHighlight !== -1) {
                    const activeEntry = activeMenuEntries.find(e => e.index === activeHighlight)!.entry;
                    onClose();
                    activeEntry.callback && activeEntry.callback();
                }
                break;
            case KC_ESCAPE:
                onClose();
                break;
        }
    };

    if (isContentEmpty(content)) {
        return null;
    }

    return <div className="context-menu-vertical-wrapper">
        <div className="context-menu-vertical-spacer" style={{flexBasis: y}}/>
        <div className="context-menu-horizontal-wrapper">
            <div className="context-menu-horizontal-spacer" style={{flexBasis: x}}/>
            <div ref={ref}
                 className={"ContextMenu"}
                 tabIndex={0}
                 onKeyDown={e => handleKeyPress(e.keyCode)}
                 onContextMenu={e => e.preventDefault()}
                 onBlur={onClose}>
                <ContextSubMenu isRoot={true}
                                content={content}
                                highlight={highlight}
                                setHighlight={setHighlight}
                                onChoiceMade={onClose}/>
            </div>
        </div>
    </div>;
};

interface SubContextMenuProps {
    isRoot: boolean;
    content: ContextMenuData;
    highlight: number[];
    setHighlight: (highlight: number[]) => void;
    onChoiceMade: () => void;
}

const ContextSubMenu: React.FC<SubContextMenuProps> = ({isRoot, content, highlight, setHighlight, onChoiceMade}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [direction, setDirection] = useState<"left" | "right" | "no-direction">("no-direction");

    useLayoutEffect(() => {
        if (highlight.length < 1) {
            setHighlight([-1]);
        }

        if (direction === "no-direction") {
            if (ref.current!.getBoundingClientRect().right > document.body.clientWidth) setDirection("left");
            else setDirection("right");
        }
    });

    let index = -1;
    return <div ref={ref} className={classNames("ContextSubMenu", {"is-root": isRoot, [direction]: true})}>
        {content.map(group => <div key={group.map(e => e.text).join("/")} className="group">
            {group.map(e => {
                let i = ++index;
                return <ContextMenuEntry key={e.text}
                                         {...e}
                                         highlighted={i === highlight[0]}
                                         onHighlight={() => setHighlight([i])}
                                         onUnhighlight={() => setHighlight([])}
                                         subMenuHighlight={highlight.slice(1)}
                                         setSubMenuHighlight={newHighlight => setHighlight([i, ...newHighlight])}
                                         onChoiceMade={onChoiceMade}/>
            })}
        </div>)}
    </div>
};

interface ContextMenuEntryProps extends ContextMenuEntryData {
    highlighted: boolean;
    onHighlight: () => void;
    onUnhighlight: () => void;
    subMenuHighlight: number[];
    setSubMenuHighlight: (highlight: number[]) => void;
    onChoiceMade: () => void;
}

const ContextMenuEntry: React.FC<ContextMenuEntryProps> = (
    {
        text, icon, shortcut, callback, subMenu, selectable = true, enabled = true, highlighted,
        onHighlight, onUnhighlight, subMenuHighlight, setSubMenuHighlight, onChoiceMade
    }
) => {
    return <div className={classNames("ContextMenuEntry", highlighted ? "highlighted" : undefined)}
                onMouseEnter={() => selectable && enabled && !highlighted && onHighlight()}
                onMouseLeave={() => highlighted && onUnhighlight()}
                onClick={() => {
                    if (enabled && selectable && !subMenu) {
                        onChoiceMade();
                        callback && callback();
                    }
                }}>
        {icon && <span className={classNames("entry-icon", {"disabled": !enabled})}>
            <FontAwesomeIcon icon={icon} size="xs"/>
        </span>}
        <span className={classNames("entry-text", {"disabled": !enabled})}>{text}</span>
        {shortcut && <span className="shortcut">{shortcut}</span>}
        {subMenu && <SubMenuIcon/>}
        {subMenu && highlighted && <ContextSubMenu isRoot={false}
                                                   content={subMenu}
                                                   highlight={subMenuHighlight}
                                                   setHighlight={setSubMenuHighlight}
                                                   onChoiceMade={onChoiceMade}/>}
    </div>
};

function SubMenuIcon() {
    return <svg className="SubMenuIcon" width="6" height="11">
        <polygon points="0,0 0,10 5,5" fill="white"/>
    </svg>;
}
