import {Component} from "react";
import {FileData, FileInfo} from "./api-model";
import {v4 as uuid} from "uuid";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {faFolderClosed} from "@fortawesome/free-solid-svg-icons/faFolderClosed";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons/faFilePdf";
import {faFileAudio} from "@fortawesome/free-solid-svg-icons/faFileAudio";
import {faFileCode} from "@fortawesome/free-solid-svg-icons/faFileCode";
import {faWindowMaximize} from "@fortawesome/free-solid-svg-icons/faWindowMaximize";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons/faFileCsv";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons/faFileExcel";
import {faFileImage} from "@fortawesome/free-solid-svg-icons/faFileImage";
import {faFileLines} from "@fortawesome/free-solid-svg-icons/faFileLines";
import {faFileWord} from "@fortawesome/free-solid-svg-icons/faFileWord";
import {faFileVideo} from "@fortawesome/free-solid-svg-icons/faFileVideo";
import {faFilePowerpoint} from "@fortawesome/free-solid-svg-icons/faFilePowerpoint";
import {faFileZipper} from "@fortawesome/free-solid-svg-icons/faFileZipper";
import {faFile} from "@fortawesome/free-solid-svg-icons/faFile";

export type Dict<T> = {[key: string]: T};

export const ItemTypes = {FILE_ENTRIES: "paths"};
export type FileEntriesDropItem = {paths: string[], draggedPath: string};

export function normPath(p: string) {
    let norm = p.replaceAll(/\/+/g, "/");
    if (norm.endsWith("/")) norm = norm.substring(0, norm.length - 1);
    if (!norm.startsWith("/")) norm = "/" + norm;
    return norm;
}

export function concatPaths(p1: string, p2: string): string {
    return normPath([p1, p2].join("/").replace("//", "/"));
}

export function getNameFromPath(path: string): string {
    return path === "/" ? "/" : path.substring(path.lastIndexOf("/") + 1);
}

export function getFileExt(name: string): string {
    if (name.indexOf(".") !== -1) {
        return name.substring(name.lastIndexOf(".") + 1);
    } else return "";
}

export function getNextAvailableFileName(name: string, existingNames: string[]): string {
    const ext = getFileExt(name);
    let baseName = name.substring(0, name.length - ext.length - (ext.length ? 1 : 0));

    const countMatch = baseName.match(/ \(\d+\)$/);
    if (countMatch?.length) {
        baseName = baseName.substring(0, baseName.length - countMatch[0].length);
    }

    for (let i = 2; true; i++) {
        const nextName = `${baseName} (${i})${ext ? "." + ext : ""}`;
        if (!existingNames.includes(nextName)) {
            return nextName;
        }
    }
}

export function getParentPath(path: string): string {
    if (path === "/") throw "tried to get parent of root";
    return normPath(path.substring(0, path.lastIndexOf("/")));
}

export function findDescendantFileInfo(parent: FileInfo, path: string): FileInfo | undefined {
    if (path.startsWith("/")) path = path.substring(1);
    if (!path) return parent;
    const nextChildName = path.split("/")[0];
    const nextRelPath = path.substring(nextChildName.length + 1);
    const child = parent.children?.find(c => c.name === nextChildName);
    if (!child) return undefined;
    else return findDescendantFileInfo(child, nextRelPath);
}

export function isPathAncestorOfOtherPath(path: string, otherPath: string): boolean {
    if (otherPath === "/") return false;
    const otherParent = getParentPath(otherPath);
    return path === otherParent || isPathAncestorOfOtherPath(path, getParentPath(otherPath));
}

export function getAncestorPaths(path: string): string[] {
    if (path === "/") return [];
    const parentPath = getParentPath(path);
    return parentPath.split("/").map((v, i, a) => normPath(a.slice(0, i + 1).join("/")));
}

export function getUrlToPath(path: string): string {
    return path.split("/").map(p => encodeURIComponent(p)).join("/");
}

export interface RouterProps {
    currentPath: string;
    navigate: (to: string) => void;
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function prettyByteLabel(bytes: number, si: boolean = true, dp: number = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

export async function setStateAsync<K extends keyof S, P = {}, S = {}>(component: Component<any, S>,
                                                                       state: ((prevState: Readonly<S>, props: Readonly<P>) => (Pick<S, K> | S | null)) | (Pick<S, K> | S | null)) {
    return new Promise<void>(resolve => component.setState(state, resolve));
}

export function clamp(x: number, min: number, max: number): number {
    return Math.min(Math.max(x, min), max);
}

export function isStringValidFileName(str: string) {
    if (!str ||
        !!str.match(/[\x00-\x1F]/) || // contains non-printable characters
        str !== str.trim() || // starts or ends with whitespaces
        str.length < 1 ||
        str.length > 255 ||
        str === "." ||
        str === "..") return false;
    else return !str.match(/[/\x00]/);
}

export function getPromiseLockFn(promiseMap: Map<string, Promise<any>>, onLoadingStateChange: (loading: boolean) => void) {
    return <IN extends Array<any>, OUT extends any = void>(fn: (...args: IN) => Promise<OUT>) => {
        return async (...args: IN): Promise<OUT> => {
            const existingPromises = mapValues(promiseMap);

            const lockId = uuid();
            const promise = new DeferredPromise<OUT>();
            promiseMap.set(lockId, promise);
            if (!existingPromises.length) onLoadingStateChange(true);

            await Promise.allSettled(existingPromises).then(async () => {
                try {
                    promise.resolve(await fn(...args));
                } catch (e) {
                    promise.reject(e);
                } finally {
                    promiseMap.delete(lockId);
                    if (!promiseMap.size) onLoadingStateChange(false);
                }
            });

            return promise;
        };
    };
}

export function mapValues<K, V>(map: Map<K, V>): V[] {
    return Array.from(map.values());
}

export class DeferredPromise<T = void> implements Promise<T> {

    private _promise: Promise<T>;
    private _resolve!: (v: T | PromiseLike<T>) => void;
    private _reject!: (r?: any) => void;

    constructor() {
        this._promise = new Promise<T>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
        this.then = this.then.bind(this);
        this.catch = this.catch.bind(this);
        this.finally = this.finally.bind(this);
        this.resolve = this.resolve.bind(this);
        this.reject = this.reject.bind(this);
    }

    public then<TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
                                                onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null): Promise<TResult1 | TResult2> {
        return this._promise.then(onfulfilled, onrejected)
    }

    public catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): Promise<T | TResult> {
        return this._promise.then(onrejected)
    }

    public finally(onfinally?: (() => void) | undefined | null): Promise<T> {
        return this._promise.finally(onfinally);
    }

    public resolve(val: T) { this._resolve(val) }
    public reject(reason?: any) { this._reject(reason) }

    [Symbol.toStringTag]: 'Promise'

}

export function noop() {}

export type EmptyResource<T> = {
    state: "EMPTY";
    data?: undefined;
    promise?: undefined;
    error?: undefined;
}

export type LoadingResource<T> = {
    state: "LOADING";
    data?: T;
    promise: Promise<T>;
    error?: undefined;
};

export type FailedResource<T> = {
    state: "ERROR";
    data?: undefined;
    promise: Promise<T>;
    error?: string;
};

export type SuccessfulResource<T> = {
    state: "OK";
    data: T;
    promise: Promise<T>;
    error?: undefined;
};

export type Resource<T> = EmptyResource<T> | LoadingResource<T> | FailedResource<T> | SuccessfulResource<T>;

export function getIconForFileData(file: FileData): IconDefinition {
    return file.ftype === "d" ? faFolderClosed : getIconForExtension(getFileExt(file.name));
}

export function getIconForExtension(ext: string): IconDefinition {
    ext = ext.toLowerCase();
    if (ext === "pdf") return faFilePdf;
    else if (["mp3", "m4a", "flac", "wav", "wma", "aac"].includes(ext)) return faFileAudio;
    else if (["c", "cpp", "java", "js", "ts", "tsx", "html", "css", "xml"].includes(ext)) return faFileCode;
    else if (["exe"].includes(ext)) return faWindowMaximize;
    else if (["csv"].includes(ext)) return faFileCsv;
    else if (["xls", "xlsx"].includes(ext)) return faFileExcel;
    else if (["png", "jpeg", "jpg", "gif", "tif", "tiff", "bmp", "eps", "svg"].includes(ext)) return faFileImage;
    else if (["txt", "json", "ini", "yaml", "conf"].includes(ext)) return faFileLines;
    else if (["doc", "docx"].includes(ext)) return faFileWord;
    else if (["mp4", "mov", "wmv", "avi", "avchd", "flv", "f4v", "swf", "mkv", "webm"].includes(ext)) return faFileVideo;
    else if (["ppt", "pptx", "pptm"].includes(ext)) return faFilePowerpoint;
    else if (["zip", "gz", "7z", "jar"].includes(ext)) return faFileZipper;
    else return faFile;
}

export function tryParseJson<T>(str: string | undefined | null, def: T): T {
    if (!str) return def;
    try {
        return JSON.parse(str);
    } catch (e) {
        console.warn("Unable to parse JSON: " + str);
        return def;
    }
}

export function isMobile(): boolean {
    return 'ontouchstart' in document.documentElement;
}
