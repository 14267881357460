import "./Overlay.scss"
import React, {HTMLAttributes} from "react";
import classNames from "classnames";

export interface OverlayProps extends HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    fullscreen?: boolean;
    centerContent?: boolean;
    nonBlocking?: boolean;
}

export function Overlay(props: OverlayProps) {
    const {children, fullscreen, centerContent = true, nonBlocking, ...divProps} = props;

    const className = classNames("Overlay", {fullscreen, "center-content": centerContent, "non-blocking": nonBlocking}, divProps.className);
    return <div {...divProps} className={className}>
        {children}
    </div>;
}