import {useEffect, useState} from "react";
import {clamp, tryParseJson} from "./util";

export interface SortOptions<T> {
    initial?: SortBy<T>;
    comparators?: {[prop: string]: (a: unknown, b: unknown) => number};
    localStorageKey?: string;
}

export type SortBy<T> = {key: keyof T, ascending: boolean};

export type SortedHook<T> = {
    sorted: T[];
    sortedBy: SortBy<T> | undefined;
    setSortBy: (newSort: SortBy<T> | undefined) => void;
    toggleSort: (key: keyof T) => void;
};

export function useSorted<T>(unsorted: T[], options?: SortOptions<T>): SortedHook<T> {
    const [sortBy, setSortBy] = useState<SortBy<T> | undefined>(() => getInitialSort(options));

    const getCompareFn = (sortBy: SortBy<T> | undefined) =>{
        return (a: T, b: T) => {
            if (!sortBy) return 0;
            const comparator = options?.comparators?.[sortBy.key as string] ?? defaultCompare;
            return comparator(a[sortBy.key], b[sortBy.key]) * (sortBy.ascending ? 1 : -1);
        };
    }

    const [sorted, setSorted] = useState(() => [...unsorted].sort(getCompareFn(sortBy)));

    useEffect(() => {
        setSorted([...unsorted].sort(getCompareFn(sortBy)));

        if (options?.localStorageKey) {
            if (sortBy) localStorage.setItem(options.localStorageKey, JSON.stringify(sortBy));
            else localStorage.removeItem(options.localStorageKey);
        }
    }, [sortBy, unsorted]);

    const toggleSort = (key: keyof T) => {
        if (sortBy?.key === key) setSortBy({key, ascending: !sortBy.ascending});
        else setSortBy({key, ascending: false});
    };

    return {sorted, sortedBy: sortBy, setSortBy, toggleSort};
}

function getInitialSort<T>(options?: SortOptions<T>) {
    if (options?.localStorageKey) {
        const saved = localStorage.getItem(options.localStorageKey);
        const parsed = tryParseJson(saved, undefined);
        if (parsed) return parsed;
    }
    return options?.initial;
}

function defaultCompare<T = any>(a: T, b: T): number {
    if (typeof a === "number" && typeof b === "number") return clamp(a - b, -1, 1);
    else if (typeof a === "string" && typeof b === "string") return a.localeCompare(b);
    else return 0;
}