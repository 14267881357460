import "./file.scss";
import React, {HTMLAttributes, useContext, useEffect, useState} from "react";
import {ProgressDialogProps} from "./components/ProgressDialog";
import {DropEvent, FileRejection, FileWithPath, useDropzone} from "react-dropzone";
import {
    concatPaths,
    FileEntriesDropItem,
    findDescendantFileInfo,
    getFileExt,
    getIconForExtension,
    getNameFromPath,
    getParentPath,
    isPathAncestorOfOtherPath,
    isStringValidFileName,
    ItemTypes,
    prettyByteLabel
} from "./util";
import {toast} from "react-toastify";
import {DriveContext, DriveContextType} from "./context";
import {toastErrorMessage} from "./toast-util";
import {useDrag, useDragLayer, useDrop} from "react-dnd";
import classNames from "classnames";
import {KeyBindActionMap, keyBindCode, toEventConsumer} from "./keybind-util";
import {FILE_UPLOAD_MAX_SIZE} from "./constants";
import {faCopy} from "@fortawesome/free-solid-svg-icons/faCopy";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {faFolderClosed} from "@fortawesome/free-solid-svg-icons/faFolderClosed";
import {ContextMenuData, ContextMenuEntryData} from "./components/ContextMenu";
import {faFileCirclePlus} from "@fortawesome/free-solid-svg-icons/faFileCirclePlus";
import {faFile} from "@fortawesome/free-solid-svg-icons/faFile";
import {faFolder} from "@fortawesome/free-solid-svg-icons/faFolder";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import {API} from "./api";
import {faLink} from "@fortawesome/free-solid-svg-icons/faLink";
import {uniq} from "lodash";
import {faCut} from "@fortawesome/free-solid-svg-icons/faCut";
import {faPaste} from "@fortawesome/free-solid-svg-icons/faPaste";
import {faPencil} from "@fortawesome/free-solid-svg-icons/faPencil";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";

export interface FileEntryData {
    path: string;
    isDir: boolean;
}

export function useFileSelection(paths: string[]) {
    const [selectedPaths, setSelectedPaths] = useState<string[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);

    const onFileClick = (path: string, ctrl: boolean, shift: boolean) => {
        const clickedIndex = paths.findIndex(p => p === path);
        if (clickedIndex === -1) {
            console.error(`clicked file '${path}' not found`);
            return;
        }

        if (ctrl) {
            if (selectedPaths.includes(path)) {
                setSelectedPaths(selectedPaths.filter(s => s !== path));
            } else {
                setSelectedPaths([...selectedPaths, path]);
            }
            setSelectedIndex(clickedIndex);
        } else if (shift) {
            const firstIndex = Math.min(selectedIndex || 0, clickedIndex);
            const lastIndex = Math.max(selectedIndex || 0, clickedIndex);
            const newSelection: string[] = [];
            for (let i = firstIndex; i <= lastIndex; i++) newSelection.push(paths[i]);
            setSelectedPaths(newSelection);
        } else {
            setSelectedPaths([path]);
            setSelectedIndex(clickedIndex);
        }
    };

    const resetSelection = () => {
        setSelectedPaths([]);
        setSelectedIndex(undefined);
    };

    useEffect(() => {
        const missingPaths = selectedPaths.filter(p => !paths.includes(p));
        let newSelectedIndex: number | undefined = undefined;
        if (missingPaths.length) {
            const newPaths = paths.filter(p => selectedPaths.includes(p));
            setSelectedPaths(newPaths);
            newSelectedIndex = newPaths.length ? Math.min(...selectedPaths.map(p => newPaths.indexOf(p))) : undefined;
        }
        setSelectedIndex(newSelectedIndex);
    }, [paths]);

    return {selectedPaths, onFileClick, resetSelection};
}

export function useFileUpload() {
    const context = useContext(DriveContext);

    const [upload, setUpload] = useState<ProgressDialogProps | undefined>(undefined);
    const onFilesDropped = async (dirPath: string, files: FileWithPath[]) => {
        if (!files.length) return;

        const onProgress = (uploadedBytes: number, totalBytes: number, uploadedFiles: number, totalFiles: number) => {
            const totalProgress = uploadedBytes / totalBytes;
            setUpload({
                progress: totalProgress,
                progressLabel: `${prettyByteLabel(uploadedBytes)} / ${prettyByteLabel(totalBytes)} (${Math.round(totalProgress * 100)} %)`,
                status: <>Uploaded <strong>{uploadedFiles}</strong> of <strong>{totalFiles}</strong> files</>
            });
        };

        const {failedCount} = await context.controller.upload(dirPath, files, onProgress);

        if (failedCount) {
            toast(`${failedCount} file${failedCount !== 1 ? "s" : ""} failed to upload`, {type: "error"});
        } else {
            toast(`${files.length} file${files.length !== 1 ? "s" : ""} uploaded sucessfully!`, {type: "success"});
        }

        setUpload(undefined);
    };

    return {startUpload: onFilesDropped, uploadProgress: upload};
}

export function useFileRename() {
    const context = useContext(DriveContext);
    const [renamingPath, setRenamingPath] = useState<string | undefined>(undefined);

    const onRename = async (path: string, newName: string): Promise<boolean> => {
        const parentPath = getParentPath(path);
        const parentDir = findDescendantFileInfo(context.root, parentPath);
        let success: boolean = false;
        if (isStringValidFileName(newName) && parentDir?.children && !parentDir.children.some(c => c.name === newName)) {
            try {
                await context.controller.rename(path, newName);
                success = true;
            } catch (e) {
                console.error(`Failed to rename file '${path}' to '${newName}': `, e);
                toastErrorMessage("Failed to rename file", e as string);
                success = false;
            }
        }
        setRenamingPath(undefined);
        return success;
    };

    return {renamingPath, setRenamingPath, onRename};
}

export function useFileDropZone(onFilesDropped: (files: FileWithPath[]) => void) {
    const onDrop = async <T extends FileWithPath>(acceptedFiles: T[], rejected: FileRejection[], e: DropEvent) => {
        e.stopPropagation();
        onFilesDropped(acceptedFiles);
    };
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, maxSize: FILE_UPLOAD_MAX_SIZE});
    return {getRootProps, getInputProps, isDragActive};
}

export function useFileEntryDragAndDrop(target: FileEntryData,
                                        dragItem: FileEntriesDropItem,
                                        onFileEntriesDropped: (paths: string[]) => void) {
    const [,dragRef] = useDrag<FileEntriesDropItem>(() => ({type: ItemTypes.FILE_ENTRIES, item: dragItem}), [dragItem]);
    const [{canDrop, isDropping}, dropRef] = useDrop(() => ({
        accept: ItemTypes.FILE_ENTRIES,
        canDrop: (item: FileEntriesDropItem) => canDropPaths(item.paths, target),
        drop: (item: FileEntriesDropItem) => onFileEntriesDropped(item.paths),
        collect: monitor => ({canDrop: monitor.canDrop(), isDropping: monitor.isOver()})
    }), []);
    const {isDragging, draggedPath} = useDragLayer(monitor => ({isDragging: monitor.isDragging(), draggedPath: (monitor.getItem() as FileEntriesDropItem)?.draggedPath}));
    return {dragRef, dropRef, dragProps: {isDragging, draggedPath, canDrop, isDropping}};
}

export type FileEntryDropAreaProps = {
    path: string,
    isDragging: boolean,
    draggedPath: string,
    canDrop: boolean,
    isDropping: boolean,
    isDroppingFiles: boolean,
    children?: React.ReactNode
};

export const FileEntryDropArea = React.forwardRef<HTMLDivElement, FileEntryDropAreaProps & HTMLAttributes<HTMLDivElement>>((props, ref) => {
    const {path, isDragging, draggedPath, canDrop, isDropping, isDroppingFiles, children, ...divProps} = props;

    return <div {...divProps}
         className={classNames(props.className,
             "FileEntryDropArea", {
                 "dragging": isDragging && draggedPath !== path,
                 "can-drop": isDropping && canDrop || isDroppingFiles
             }
         )}
         ref={ref}>
        {children}
        <div className="can-drop-overlay"/>
    </div>
});

export function getCopyPasteKeyBinds(context: DriveContextType,
                                     selectedPaths: string[]): KeyBindActionMap {
    return {
        [keyBindCode({key: "C", ctrl: true})]: e => {
            if (selectedPaths.length) {
                context.setClipboard({kind: "copy", paths: selectedPaths});
                e.stopPropagation();
                e.preventDefault();
            }
        },
        [keyBindCode({key: "X", ctrl: true})]: e => {
            if (selectedPaths.length) {
                context.setClipboard({kind: "cut", paths: selectedPaths});
                e.stopPropagation();
                e.preventDefault();
            }
        },
        [keyBindCode({key: "V", ctrl: true})]: e => {
            if (selectedPaths.length === 1) {
                const selectedPath = selectedPaths[0];
                const node = findDescendantFileInfo(context.root, selectedPath);
                const targetDirectory = node?.children ? selectedPath : getParentPath(selectedPath);
                onPasteFileEntries(context, targetDirectory);
                e.stopPropagation();
                e.preventDefault();
            }
        },
    };
}

export function getFileEntryKeyBinds(context: DriveContextType,
                                     selectedPaths: string[],
                                     resetSelection: () => void,
                                     setRenamingPath: (path: string) => void): KeyBindActionMap {
    return {
        ...getCopyPasteKeyBinds(context, selectedPaths),
        [keyBindCode({key: "Escape"})]: toEventConsumer(resetSelection),
        [keyBindCode({key: "Delete"})]: e => {
            if (selectedPaths.length) {
                onDeleteFileEntries(context, selectedPaths);
                e.stopPropagation();
                e.preventDefault();
            }
        },
        [keyBindCode({key: "R", ctrl: true})]: e => {
            if (selectedPaths.length === 1 && selectedPaths[0] !== "/") {
                resetSelection();
                setRenamingPath(selectedPaths[0]);
                e.stopPropagation();
                e.preventDefault();
            }
        },
    };
}

export async function onFileEntriesDropped(context: DriveContextType, paths: string[], target: string) {
    let {failedCount, lastError} = await context.controller.move(paths, target);
    if (failedCount) {
        toastErrorMessage("Failed to move files", lastError);
    }
}

export async function onPasteFileEntries(context: DriveContextType, targetPath: string) {
    const clipboard = context.clipboard;
    if (!clipboard) return;

    let result;
    if (clipboard.kind === "copy") {
        result = await context.controller.copy(clipboard.paths, targetPath);
    } else {
        result = await context.controller.move(clipboard.paths, targetPath);
    }

    if (result.failedCount) {
        toastErrorMessage("Failed to paste some files", result.lastError);
    }
}

export async function onDeleteFileEntries(context: DriveContextType, paths: string[]) {
    const pathsToDelete = paths.filter(p => !paths.some(i => isPathAncestorOfOtherPath(i, p)));
    let {failedCount, lastError} = await context.controller.delete(pathsToDelete);
    if (failedCount) {
        toastErrorMessage("Failed to delete files", lastError);
    }
}

export function getNextNewFileName(existingFiles: string[], baseName: string): string {
    if (!existingFiles.includes(baseName)) return baseName;
    for (let i = 1; true; i++) {
        const name = `${baseName} (${i})`;
        if (!existingFiles.includes(name)) return name;
    }
}

export function getFileContextMenu(context: DriveContextType,
                                   path: string,
                                   isDir: boolean,
                                   selectedPaths: string[],
                                   onPathCreated: (newPath: string) => void,
                                   onRename: (path: string) => void): ContextMenuData | undefined {
    const isMultipleSelection = selectedPaths.length > 1;

    const targetDirectory = isDir ? path : getParentPath(path);

    const menu: ContextMenuData = [[{
        text: isMultipleSelection ? `${selectedPaths.length} Items` : (path === "/" ? "Root" : getNameFromPath(path)),
        icon: isMultipleSelection ? faCopy : getIconForEntry(path, isDir),
        selectable: false
    }]];

    if (!isMultipleSelection) {
        const targetDirectoryChildren = findDescendantFileInfo(context.root, targetDirectory)?.children?.map(c => c.name) || [];
        menu.push([{
            text: "New",
            icon: faFileCirclePlus,
            subMenu: [[
                {
                    text: "File",
                    icon: faFile,
                    callback: async () => {
                        const path = concatPaths(targetDirectory, getNextNewFileName(targetDirectoryChildren, "New File"));
                        try {
                            await context.controller.createFile(path);
                            onPathCreated(path);
                        } catch (e) {
                            toastErrorMessage("Failed to create file:", e as string);
                        }
                    }
                }, {
                    text: "Folder",
                    icon: faFolder,
                    callback: async () => {
                        const path = concatPaths(targetDirectory, getNextNewFileName(targetDirectoryChildren, "New Folder"));
                        try {
                            await context.controller.createDirectory(path);
                            onPathCreated(path);
                        } catch (e) {
                            toastErrorMessage("Failed to create folder:", e as string);
                        }
                    }
                }
            ]]
        }]);
        menu.push([{
            text: "Download",
            icon: faDownload,
            callback: async () => {
                try {
                    const shareCode = await context.controller.getShareCode(path);
                    API.triggerDownload(shareCode);
                } catch (e) {
                    toastErrorMessage("Failed to download file:", e as string);
                }
            }
        }, {
            text: "Share",
            icon: faLink,
            callback: async () => {
                try {
                    const shareCode = await context.controller.getShareCode(path);
                    const link = `${window.location.origin}/download/${shareCode}`;
                    await navigator.clipboard.writeText(link);
                    toast("Share link copied to clipboard!", {type: "success"});
                } catch (e) {
                    toastErrorMessage("Failed to download file:", e as string);
                }
            }
        }]);
    }

    const copyPasteSection = [{
        text: "Copy",
        icon: faCopy,
        shortcut: "Ctrl-C",
        callback: () => context.setClipboard({kind: "copy", paths: uniq([path, ...(selectedPaths)])})
    }, {
        text: "Cut",
        icon: faCut,
        shortcut: "Ctrl-X",
        callback: () => context.setClipboard({kind: "cut", paths: uniq([path, ...(selectedPaths)])})
    }];
    if (context.clipboard) {
        copyPasteSection.push({
            text: "Paste",
            icon: faPaste,
            shortcut: "Ctrl-V",
            callback: () => onPasteFileEntries(context, targetDirectory)
        });
    }
    menu.push(copyPasteSection);

    const lastSection: ContextMenuEntryData[] = [];
    if (!isMultipleSelection) {
        lastSection.push({
            text: "Rename",
            icon: faPencil,
            enabled: path !== "/",
            shortcut: "Ctrl-R",
            callback: () => onRename(path)
        });
    }
    lastSection.push({
        text: "Delete",
        icon: faTrash,
        enabled: path !== "/",
        shortcut: "Del",
        callback: () => onDeleteFileEntries(context, uniq([path, ...selectedPaths]).filter(p => p !== "/"))
    });
    menu.push(lastSection);

    return menu;
}

function getIconForEntry(path: string, isDir: boolean): IconDefinition {
    return isDir ? faFolderClosed : getIconForExtension(getFileExt(getNameFromPath(path)));
}

function canDropPaths(paths: string[], target: FileEntryData): boolean {
    const targetPath = target.isDir ? target.path : getParentPath(target.path);
    const noMove = paths.filter(p => p !== "/").every(p => getParentPath(p) === targetPath);
    return paths.every(path => !isPathAncestorOfOtherPath(path, targetPath) && path !== targetPath) && !noMove;
}
