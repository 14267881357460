import "./FileBrowser.scss";
import React, {useContext, useMemo, useState} from "react";
import {
    concatPaths,
    Dict,
    FileEntriesDropItem,
    findDescendantFileInfo,
    getAncestorPaths,
    getFileExt,
    getIconForExtension,
    getParentPath,
    getUrlToPath, isMobile,
    isPathAncestorOfOtherPath, noop,
    normPath,
    tryParseJson
} from "../util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {faFolderClosed} from "@fortawesome/free-solid-svg-icons/faFolderClosed";
import {faFolderOpen} from "@fortawesome/free-solid-svg-icons/faFolderOpen";
import {DriveContext} from "../context";
import {FileWithPath} from "react-dropzone";
import classNames from "classnames";
import {ProgressDialog} from "./ProgressDialog";
import {contextMenuHandler} from "../render-util";
import {EditableText} from "./EditableText";
import {FileInfo} from "../api-model";
import {SpinnerOverlay} from "./SpinnerOverlay";
import {keyboardEventHandler} from "../keybind-util";
import {joinRefs, useCurrentPath} from "../hooks";
import {
    FileEntryDropArea,
    getFileContextMenu,
    getFileEntryKeyBinds,
    onFileEntriesDropped,
    useFileDropZone,
    useFileEntryDragAndDrop,
    useFileRename,
    useFileSelection,
    useFileUpload
} from "../file-browser-commons";
import {useNavigate} from "react-router-dom";
import {Overlay} from "./Overlay";

type FileEntryInfo = {name: string, path: string, type: "f" | "d", depth: number};

export function FileBrowser() {
    const context = useContext(DriveContext);
    const navigate = useNavigate();
    const currentPath = useCurrentPath();
    const [openedPaths, setOpenedPaths] = useState(getInitialOpenedPaths(currentPath));

    const {renamingPath, setRenamingPath, onRename} = useFileRename();

    const entries = useMemo(() => {
        const rootEntry: FileEntryInfo = {name: context.root.name || "/", path: "/", type: "d", depth: 0};
        const entries: FileEntryInfo[] = [rootEntry];
        addDirectoryToEntriesDepthFirst(entries, rootEntry, context.root, openedPaths);
        return entries;
    }, [context.root, openedPaths]);

    const isPathOpen = (path: string) => openedPaths[path] && !!findDescendantFileInfo(context.root, path)?.children;

    const setDirectoryOpen = (path: string, open: boolean) => {
        if (!findDescendantFileInfo(context.root, path)) {
            console.error(`Tried to open/close non-existent directory: '${path}'`);
        } else {
            setOpenedPaths(prevState => ({...prevState, [path]: open}));
            savePathOpenState(path, open);
        }
    }

    const toggleDirectory = (path: string) => setDirectoryOpen(path, !openedPaths[path]);

    const allPaths = useMemo(() => entries.map(e => e.path), [entries]);
    const {selectedPaths, onFileClick, resetSelection} = useFileSelection(allPaths);

    const onEntryClicked = (evt: React.MouseEvent, entry: FileEntryInfo) => {
        if (!evt.ctrlKey && !evt.shiftKey) {
            navigate(getUrlToPath(entry.path));
        }
        onFileClick(entry.path, evt.ctrlKey, evt.shiftKey);
    };

    const onEntryIconClicked = (evt: React.MouseEvent, entry: FileEntryInfo) => {
        if (entry.type === "d" && entry.path !== "/") {
            evt.stopPropagation();
            toggleDirectory(entry.path);
        }
    };

    const onKeyDown = keyboardEventHandler(getFileEntryKeyBinds(context, selectedPaths, resetSelection, setRenamingPath));

    const {startUpload, uploadProgress} = useFileUpload();

    const onPathCreated = (newPath: string) => {
        setDirectoryOpen(getParentPath(newPath), true);
        setRenamingPath(newPath);
    };

    const onPathMoved = async (oldPath: string, newPath: string) => {
        // make sure openedPaths state remains consistent
        setOpenedPaths(prevState => {
            const newOpenedPaths = {...prevState};
            const affectedOpenedPaths = Object.keys(prevState).filter(p => isPathAncestorOfOtherPath(oldPath, p) || oldPath === p);
            for (let affectedPath of affectedOpenedPaths) {
                const value = newOpenedPaths[affectedPath];
                delete newOpenedPaths[affectedPath];
                const fixedPath = normPath(affectedPath.replace(oldPath, newPath));
                newOpenedPaths[fixedPath] = value;
            }
            return newOpenedPaths;
        });
    };

    // for drop zone represented by empty space under last file entry
    const {getRootProps, getInputProps, isDragActive} = useFileDropZone(files => startUpload("/", files));

    return <div className="FileBrowser" onKeyDown={onKeyDown}>
        {entries.map((e, index) => {
            const isOpen = isPathOpen(e.path);
            const parentPath = e.path !== "/" ? getParentPath(e.path) : undefined;
            return <FileEntry key={e.path}
                              entry={e}
                              isOpen={isOpen}
                              isSelected={selectedPaths.includes(e.path)}
                              isActive={currentPath === e.path}
                              nextEntryDepth={index < entries.length - 1 ? entries[index + 1].depth : 0}
                              onClick={evt => onEntryClicked(evt, e)}
                              onIconClick={evt => onEntryIconClicked(evt, e)}
                              onFilesDropped={files => startUpload(e.type === "d" ? e.path : parentPath!, files)}
                              onFileEntriesDropped={paths => onFileEntriesDropped(context, paths, e.type === "d" ? e.path : getParentPath(e.path))}
                              onContextMenu={contextMenuHandler(() => getFileContextMenu(context, e.path, e.type === "d", selectedPaths, onPathCreated, setRenamingPath))}
                              dragItem={{paths: selectedPaths.includes(e.path) ? selectedPaths : [e.path], draggedPath: e.path}}
                              renaming={renamingPath === e.path}
                              onRename={newName => onRename(e.path, newName).then(success => {
                                  if (success) onPathMoved(e.path, concatPaths(getParentPath(e.path), newName));
                              })}
                              onRenameCancel={() => setRenamingPath(undefined)}/>
        })}

        <div {...getRootProps()}
             className="root-drop-area"
             onClick={e => isMobile() ? getRootProps().onClick?.(e) : noop()}
             onContextMenu={contextMenuHandler(() => getFileContextMenu(context, "/", true, selectedPaths, onPathCreated, setRenamingPath))}>
            <input {...getInputProps()}/>
            {isDragActive && <Overlay nonBlocking>
                <div className="drop-label">Drop files to upload</div>
            </Overlay>}
        </div>

        {uploadProgress && <ProgressDialog status={uploadProgress.status} progress={uploadProgress.progress} progressLabel={uploadProgress.progressLabel}/>}
        {context.loading && <SpinnerOverlay/>}
    </div>;
}

interface FileEntryProps {
    entry: FileEntryInfo;
    isOpen?: boolean;
    isSelected: boolean;
    isActive: boolean;
    nextEntryDepth: number;
    onClick: (e: React.MouseEvent) => void;
    onIconClick?: (e: React.MouseEvent) => void;
    onFilesDropped: (files: FileWithPath[]) => void;
    onFileEntriesDropped: (paths: string[]) => void;
    onContextMenu: (e: React.MouseEvent) => void;
    dragItem: FileEntriesDropItem;
    renaming: boolean;
    onRename: (newName: string) => void;
    onRenameCancel: () => void;
}

function FileEntry(props: FileEntryProps) {
    const {
        entry, isOpen = false, isSelected, isActive, nextEntryDepth, onClick, onIconClick, onFilesDropped, onFileEntriesDropped, onContextMenu,
        dragItem, renaming, onRename, onRenameCancel
    } = props;

    const {dragRef, dropRef, dragProps} = useFileEntryDragAndDrop({path: entry.path, isDir: entry.type === "d"}, dragItem, onFileEntriesDropped);
    const {getRootProps, getInputProps, isDragActive} = useFileDropZone(onFilesDropped);
    const dragHandleRef = joinRefs(dragRef, dropRef);

    return <div {...getRootProps()}
                className={classNames("FileEntry", {selected: isSelected, active: isActive})}
                onClick={onClick}
                onContextMenu={onContextMenu}>
        <input {...getInputProps()} className="drop-input" disabled={true}/>
        {[...Array(entry.depth).keys()].map(curDepth => (
            <div key={curDepth} className="hierarchy-lines">
                <div className={classNames("line", {"half-height": curDepth >= nextEntryDepth})}/>
                {entry.type === "d" && curDepth === entry.depth - 1 && <div className="tick"/>}
            </div>
        ))}
        <FileEntryDropArea ref={dragHandleRef}
                           {...dragProps}
                           path={entry.path}
                           isDroppingFiles={isDragActive}>
            <div className={classNames("icon", {"folder": entry.type === "d"})} onClick={onIconClick}>
                <FontAwesomeIcon icon={getIconForEntry(entry, isOpen)}/>
            </div>
            <EditableText className="label"
                          editing={renaming}
                          title={entry.name}
                          onChanged={onRename}
                          onCancel={onRenameCancel}>
                {entry.name}
            </EditableText>
        </FileEntryDropArea>
    </div>;
}

function addDirectoryToEntriesDepthFirst(entries: FileEntryInfo[], nodeEntry: FileEntryInfo, nodeInfo: FileInfo, openedPaths: Dict<boolean>) {
    if (!nodeInfo.children) return;

    for (let entryInfo of nodeInfo.children) {
        const entryPath = concatPaths(nodeEntry.path, entryInfo.name);
        const childEntry: FileEntryInfo = {
            name: entryInfo.name,
            path: entryPath,
            type: entryInfo.children ? "d" : "f",
            depth: entryPath === "/" ? 0 : entryPath.split("").filter(c => c === "/").length
        };
        entries.push(childEntry);
        if (openedPaths[entryPath] && entryInfo.children) addDirectoryToEntriesDepthFirst(entries, childEntry, entryInfo, openedPaths);
    }
}

function getSavedOpenedPaths(): Dict<boolean> | undefined {
    const saved = localStorage.getItem("MainPage.opened-paths");
    return tryParseJson(saved, undefined);
}

function savePathOpenState(path: string, open: boolean) {
    const openedPaths: Dict<boolean> = getSavedOpenedPaths() || {};
    openedPaths[path] = open;
    localStorage.setItem("MainPage.opened-paths", JSON.stringify(openedPaths));
}

function getInitialOpenedPaths(path: string): Dict<boolean> {
    const openedPaths: Dict<boolean> = getSavedOpenedPaths() || {};
    openedPaths["/"] = true;
    if (path === "/") return openedPaths;

    const pathsToOpen = [...getAncestorPaths(path), path];
    for (let path of pathsToOpen) {
        openedPaths[path] = true;
    }
    return openedPaths;
}

function getIconForEntry(entry: FileEntryInfo, isOpen: boolean = false): IconDefinition {
    return entry.type === "d" ? (isOpen ? faFolderOpen : faFolderClosed) : getIconForExtension(getFileExt(entry.name));
}
