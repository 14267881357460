import React from "react";
import {ContextMenu, ContextMenuData} from "./components/ContextMenu";
import {createRoot, Root} from "react-dom/client";

export const CONTEXT_MENU_ANCHOR_ID = "context-menu";

function ensureContextMenuAnchorExists() {
    if (!document.getElementById(CONTEXT_MENU_ANCHOR_ID)) {
        const newChild = document.createElement("div");
        newChild.id = CONTEXT_MENU_ANCHOR_ID;
        document.body.appendChild(newChild);
    }
}

let currentRoot: Root | undefined = undefined;

export function openContextMenu(x: number, y: number, contextMenuData: ContextMenuData, onClose: () => void = closeContextMenu) {
    ensureContextMenuAnchorExists();
    closeContextMenu();

    currentRoot = createRoot(document.getElementById(CONTEXT_MENU_ANCHOR_ID)!);
    currentRoot.render(<ContextMenu x={x}
                                    y={y}
                                    content={contextMenuData}
                                    onClose={onClose}/>);
}

export function closeContextMenu() {
    if (currentRoot) {
        currentRoot.unmount();
        currentRoot = undefined;
    }
}

export function contextMenuHandler(getContextMenuData: () => ContextMenuData | undefined) {
    return (event: React.MouseEvent) => {
        const data = getContextMenuData();
        if (data) {
            event.preventDefault();
            openContextMenu(event.clientX, event.clientY, data);
        }
    };
}
