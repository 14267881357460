import {
    concatPaths,
    findDescendantFileInfo,
    getAncestorPaths,
    getNameFromPath,
    getNextAvailableFileName,
    getParentPath,
    getPromiseLockFn, getUrlToPath, isPathAncestorOfOtherPath, normPath
} from "./util";
import {API} from "./api";
import {FileInfo} from "./api-model";
import {FileWithPath} from "react-dropzone";
import {getCurrentPath} from "./hooks";

export class FileSystemController {

    private promiseMap = new Map<string, Promise<any>>();
    private root: FileInfo = {name: "", children: []};

    constructor(private onLoadingStateChange: (loading: boolean) => void,
                private setRootNode: (root: FileInfo) => Promise<void>,
                private navigate: (url: string) => void) {}

    private lockFn = getPromiseLockFn(this.promiseMap, this.onLoadingStateChange);

    loadFileSystem = this.lockFn(async () => {
        const response = await API.loadFileSystem();
        this.root = response.root;
        await this.setRootNode(this.root);

        const currentPath = getCurrentPath();
        if (!findDescendantFileInfo(this.root, currentPath)) {
            // current path doesn't exist, go to nearest existing parent
            let next = currentPath;
            while (!findDescendantFileInfo(this.root, next)) {
                next = getParentPath(next);
            }
            this.navigate(getUrlToPath(next));
        }

        return this.root;
    });

    createFile = this.lockFn(async (path: string) => {
        // create file
        await API.write(path, "");

        // add to hierarchy
        this.addNode(path, false);
        await this.setRootNode(this.root);
    });

    createDirectory = this.lockFn(async (path: string) => {
        // create directory
        await API.mkdir(path);

        // add to hierarchy
        this.addNode(path, true);
        await this.setRootNode(this.root);
    });

    rename = this.lockFn(async (path: string, newName: string) => {
        const newPath = concatPaths(getParentPath(path), newName);
        await API.move(path, newPath);
        this.moveNode(path, newPath);
        await this.setRootNode(this.root);

        const currentPath = getCurrentPath();
        if (path === currentPath || isPathAncestorOfOtherPath(path, currentPath)) {
            this.navigate(getUrlToPath(normPath(currentPath.replace(path, newPath))));
        }
    });

    move = this.lockFn(async (paths: string[], target: string) => {
        let failedCount = 0;
        let lastError = "";
        for (let path of paths) {
            try {
                const newPath = concatPaths(target, getNameFromPath(path));
                if (path === newPath) continue;
                await API.move(path, newPath);
                this.moveNode(path, newPath);
            } catch (e) {
                console.error(`failed to move file ('${path}' to '${target}'): `, e);
                lastError = e as string;
                failedCount++;
            }
        }
        await this.setRootNode(this.root);

        const currentPath = getCurrentPath();
        const movedAncestorOfCurrentPath = paths.find(path => path === currentPath || isPathAncestorOfOtherPath(path, currentPath));
        if (movedAncestorOfCurrentPath) {
            const movedName = getNameFromPath(movedAncestorOfCurrentPath);
            this.navigate(getUrlToPath(normPath(currentPath.replace(movedAncestorOfCurrentPath, concatPaths(target, movedName)))));
        }

        return {failedCount, lastError};
    });

    copy = this.lockFn(async (paths: string[], target: string) => {
        const targetDir = findDescendantFileInfo(this.root, target);
        if (!targetDir?.children) return {failedCount: paths.length, lastError: "Directory not found: " + target};

        let failedCount = 0;
        let lastError = "";
        for (let path of paths) {
            let fileName = getNameFromPath(path);
            if (targetDir.children.some(c => c.name === fileName)) {
                fileName = getNextAvailableFileName(fileName, targetDir.children.map(c => c.name));
            }

            const newPath = concatPaths(target, fileName);
            try {
                if (path === newPath) continue;
                await API.copy(path, newPath);
                this.copyNode(path, newPath);
            } catch (e) {
                console.error(`failed to copy file ('${path}' to '${target}'): `, e);
                lastError = e as string;
                failedCount++;
            }
        }
        await this.setRootNode(this.root);
        return {failedCount, lastError};
    });

    upload = this.lockFn(async (dirPath: string,
                                files: FileWithPath[],
                                onProgress: (uploadedBytes: number, totalBytes: number, uploadedFiles: number, totalFiles: number) => void) => {
        const totalSize = Math.max(1, files.map(f => f.size).reduce((c, p) => c + p, 0));
        let finishedSize = 0;
        let uploadedFiles = 0;

        let failedCount = 0;
        let lastError = "";

        for (let file of files) {
            try {
                const path = concatPaths(dirPath, file.path || file.name);
                await API.upload(path, file, (progress: number) => {
                    const uploadedSize = finishedSize + progress * file.size;
                    onProgress(uploadedSize, totalSize, uploadedFiles, files.length);
                });
                uploadedFiles++;
                finishedSize += file.size;
                this.addNode(path, false);
            } catch (e) {
                console.error("Failed to upload file: ", e);
                lastError = e as string;
                failedCount++;
            }
        }
        await this.setRootNode(this.root);
        return {failedCount, lastError};
    });

    delete = this.lockFn(async (paths: string[]) => {
        let failedCount = 0;
        let lastError = "";
        for (let path of paths) {
            try {
                await API.remove(path);
                this.removeNode(path);
            } catch (e) {
                console.error("Failed to delete file: ", e);
                lastError = e as string;
                failedCount++;
            }
        }
        await this.setRootNode(this.root);
        return {failedCount, lastError};
    });

    getShareCode = this.lockFn(async (path: string) => {
        return await API.getShareCode(path);
    });

    private addNode = (path: string, isDir: boolean) => {
        const name = getNameFromPath(path);
        this.root = createAllDirectories(this.root, getParentPath(path));
        this.root = putDescendant(this.root, getParentPath(path), {name, children: isDir ? [] : undefined});
    };

    private removeNode = (path: string) => {
        this.root = removeDescendant(this.root, path);
    };

    private moveNode = (oldPath: string, newPath: string) => {
        const oldName = getNameFromPath(oldPath);
        const oldParentPath = getParentPath(oldPath);
        const oldParent = findDescendantFileInfo(this.root, oldParentPath);
        const oldFileInfo = oldParent?.children?.find(c => c.name === oldName);

        const newName = getNameFromPath(newPath);
        const newParentPath = getParentPath(newPath);
        const newParent = findDescendantFileInfo(this.root, newParentPath);

        if (oldFileInfo && newParent?.children) {
            if (oldParentPath === newParentPath) {
                // rename
                this.root = replaceDescendant(this.root, oldParentPath, oldFileInfo, {...oldFileInfo, name: newName});
            } else {
                // remove old reference
                this.root = removeDescendant(this.root, oldPath);

                // add new reference
                this.root = putDescendant(this.root, newParentPath, {...oldFileInfo, name: newName});
            }
        }
    };

    private copyNode = (path: string, newPath: string) => {
        const copiedChild = findDescendantFileInfo(this.root, path);
        if (copiedChild) {
            const newChild: FileInfo = JSON.parse(JSON.stringify(copiedChild));
            newChild.name = getNameFromPath(newPath);
            this.root = putDescendant(this.root, getParentPath(newPath), newChild);
        }
    };

}

function putDescendant(ancestor: FileInfo, parentPath: string, newChild: FileInfo): FileInfo {
    return modifyDescendant(ancestor, parentPath, parent => putChild(parent, newChild));
}

function removeDescendant(ancestor: FileInfo, path: string): FileInfo {
    return modifyDescendant(ancestor, getParentPath(path), parent => removeChild(parent, getNameFromPath(path)));
}

function replaceDescendant(ancestor: FileInfo, parentPath: string, oldChild: FileInfo, newChild: FileInfo): FileInfo {
    return modifyDescendant(ancestor, parentPath, parent => {
        return putChild(parent, newChild, oldChild.name);
    });
}

function createAllDirectories(root: FileInfo, path: string): FileInfo {
    const allPaths = [...getAncestorPaths(path), path];
    let newRoot = root;
    for (let ancestorPath of allPaths) {
        const fileInfo = findDescendantFileInfo(newRoot, ancestorPath);
        const name = getNameFromPath(ancestorPath);
        if (!fileInfo) {
            newRoot = putDescendant(newRoot, getParentPath(ancestorPath), {name: name, children: []});
        } else if (fileInfo && !fileInfo.children) {
            throw `Cannot create directory '${name}', it is already a file.`;
        }
    }
    return newRoot;
}

function modifyDescendant(ancestor: FileInfo, descendantPath: string, modify: (descendant: FileInfo) => FileInfo): FileInfo {
    if (!ancestor.children) throw "tried to modify descendant of non-directory node";
    if (descendantPath.startsWith("/")) descendantPath = descendantPath.substring(1);

    if (!descendantPath) {
        // current ancestor is direct parent of target
        return modify(ancestor);
    } else {
        // continue recursing
        const nextDescendantName = descendantPath.split("/")[0];
        const nextDescendantPath = descendantPath.substring(nextDescendantName.length + 1);
        const nextDescendant = ancestor.children.find(c => c.name === nextDescendantName);
        if (!nextDescendant) throw `Descendant '${nextDescendantName}' not found in parent '${ancestor.name}'`;

        return putChild(ancestor, modifyDescendant(nextDescendant, nextDescendantPath, modify));
    }
}

function putChild(parent: FileInfo, child: FileInfo, replacedChildName: string = child.name): FileInfo {
    const newChildren = [...parent.children!];
    const existingChildIndex = newChildren.findIndex(c => c.name === replacedChildName);
    if (existingChildIndex !== -1) {
        // replace existing element in same position
        newChildren.splice(existingChildIndex, 1, child);
    } else {
        // insert element into position with correct alphabetical order
        let i = 0;
        while (i < parent.children!.length && child.name.toLowerCase() > parent.children![i].name.toLowerCase()) i++;
        newChildren.splice(i, 0, child);
    }
    return {...parent, children: newChildren};
}

function removeChild(parent: FileInfo, childName: string): FileInfo {
    return {...parent, children: parent.children!.filter(c => c.name !== childName)};
}