import "./ProgressDialog.scss";
import React, {useEffect, useState} from "react";

export interface ProgressDialogProps {
    status: React.ReactNode;
    progress: number; // [0, 1]
    progressLabel: string;
}

export function ProgressDialog(props: ProgressDialogProps) {
    const {status, progress, progressLabel} = props;
    const [timeElapsedStr, setTimeElapsedStr] = useState("00:00");

    useEffect(() => {
        const startTs = Date.now();
        const interval = setInterval(() => {
            const totalSecondsElapsed = (Date.now() - startTs) / 1000;
            const minutesElapsed = Math.floor(totalSecondsElapsed / 60);
            const secondsElapsed = Math.floor(totalSecondsElapsed - minutesElapsed * 60);
            setTimeElapsedStr(String(minutesElapsed).padStart(2, "0") + ":" + String(secondsElapsed).padStart(2, "0"));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return <div className="ProgressDialog">
        <div className="label">
            <div className="status">{status}</div>
            <span className="time-ellapsed">{timeElapsedStr}</span>
        </div>
        <svg className="progress" width="100%" height={50} style={{border: "1px solid grey"}}>
            <rect x={0} y={0} width={Math.round(100 * progress) + "%"} height={50} fill="darkgreen"/>
            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" stroke="white" strokeWidth={1}>{progressLabel}</text>
        </svg>
    </div>
}
