import {FileInfo} from "./api-model";
import React from "react";
import {useNavigate} from "react-router-dom";
import {noop, RouterProps} from "./util";
import {useCurrentPath} from "./hooks";
import {FileSystemController} from "./file-system-controller";

export interface FileBrowserClipboard {
    kind: "cut" | "copy";
    paths: string[];
}

export interface DriveContextType {
    loading: boolean;
    root: FileInfo;
    controller: FileSystemController;
    clipboard?: FileBrowserClipboard;
    setClipboard: (clipboard: FileBrowserClipboard | null) => void;
}

const defaultContext: DriveContextType = {
    root: {name: "", children: []},
    controller: new FileSystemController(noop, () => Promise.reject(), noop),
    loading: true,
    clipboard: undefined,
    setClipboard: noop
};

export const DriveContext = React.createContext(defaultContext);

export function withRouterProps<T extends RouterProps = RouterProps>(WrappedComponent: React.ComponentType<T>) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";
    const Component = (props: Omit<T, keyof RouterProps>) => {
        const currentPath = useCurrentPath();
        const navigate = useNavigate();
        const allProps = {currentPath, navigate, ...props} as unknown as T;
        return <WrappedComponent {...allProps}/>;
    };
    Component.displayName = `withRouterProps(${displayName})`;
    return Component;
}
